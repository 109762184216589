<template>
  <div
    v-if="!coming_soon"
    div
    class="relative flex flex-col min-h-[100dvh] overflow-hidden"
  >
    <!-- Contenuto principale -->
    <div
      id="background"
      class="absolute left-0 top-0 w-full h-full -z-10"
      :style="{ backgroundImage: 'url(background.png)' }"
    ></div>

    <!-- Layer di animazione -->
    <div class="absolute inset-0 animate-fadeIn overflow-hidden z-0">
      <!-- Esempio di animazione con gradienti -->
      <div
        v-for="coin in coins"
        :key="coin.id"
        :class="['coin', coin.size]"
        :style="coin.style"
      ></div>
    </div>

    <!-- Barra superiore con sfondo bianco -->
    <div class="relative bg-white w-full z-10 py-4 shadow-lg">
      <div
        class="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0"
      >
        <!-- Logo a sinistra -->
        <div class="flex justify-center md:justify-start w-full md:w-auto">
          <img width="200" src="root_points_logo.png" alt="Logo" />
        </div>

        <!-- Testo centrale -->
        <div
          class="text-2xl md:text-3xl font-bold text-gray-800 flex items-center justify-center w-full md:w-auto"
        >
          <img
            src="/images/rootmap/token.png"
            alt="Icon"
            class="h-6 w-6 md:h-8 md:w-8 inline-block mr-2"
          />
          <div
            v-if="wallet"
            class="flex flex-row justify-center items-center space-x-2"
          >
            <div>YOUR POINTS: {{ points + userNft }}</div>
          </div>
        </div>

        <!-- Pulsanti a destra -->
        <div
          class="flex justify-center md:justify-end w-full md:w-auto gap-4 items-center"
        >
          <div>
            <button
              @click="toggleDropdown"
              class="p-2 rounded-full flex justify-center items-center"
            >
              <img src="ico_wallet.png" alt="Icon" class="h-6 w-6" />
            </button>

            <!-- Menu dropdown visibile al click -->
            <ul v-if="isDropdownOpen" class="dropdown-menu">
              <li
                v-for="wallet in rolaResult"
                :key="wallet.address"
                @click="selectWallet(wallet)"
                :class="{
                  'dropdown-item-selected':
                    wallet.address === selectedWallet.address,
                  'dropdown-item': true
                }"
              >
                <div class="wallet-label">
                  {{ wallet.label }}
                  <!-- Mostra "(current)" accanto al wallet selezionato -->
                  <span v-if="wallet.address === selectedWallet.address">
                    (current)
                  </span>
                </div>
                <div class="wallet-address">
                  {{ truncateAddress(wallet.address) }}
                </div>
              </li>
            </ul>
          </div>

          <wallet-connection
            @wallet-connected="handleWalletConnected"
            @wallet-disconnected="handleWalletDisconnected"
            @rola-result-updated="updateRolaResult"
            @select-initial-wallet="selectInitialWallet"
          ></wallet-connection>
        </div>
      </div>
    </div>

    <!-- Titolo sotto forma di immagine -->
    <div
      class="flex justify-center z-1 max-w-screen-xl flex-col mx-auto text-sm"
    >
      <div
        class="bg-yellowRadix text-gray-800 rounded-lg shadow-md pt-4 md:pt-2 pb-1 text-center flex items-center justify-center relative"
      >
        <div>
          <p class="font-bold py-3 px-3">
            Welcome to the Land of Root Points, where planting a seed today will
            ensure future rewards! To start farming Root Points, complete the
            quests below.
          </p>
          <div class="text-right">
            <p class="underline">
              <a
                class="pr-4"
                href="https://docs.rootfinance.xyz/incentive-program/root-points"
                target="_blank"
                >Discover More</a
              >
            </p>
          </div>
        </div>
        <div
          class="bg-yellowRadix absolute top-0 right-1 md:right-0.5 rounded-sm"
        >
          <button @click="removeDiv">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Sezione delle card -->
    <div class="container mx-auto px-4 pt-16 pb-8 z-1">
      <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
        <div
          :class="['card', card.disabled ? 'disabled-card' : '']"
          v-for="card in cards"
          :key="card.id"
          class="card bg-white text-gray-800 rounded-lg shadow-md p-6 flex flex-col items-center justify-between relative"
        >
          <div class="absolute top-2 right-2 flex justify-center items-center">
            <img src="/images/rootmap/token.png" alt="Icon" class="h-6 w-6" />
            <p class="font-bold ml-1 text-xs">{{ card.text }}</p>
          </div>
          <!-- Immagine -->
          <div class="image-container w-[120%]">
            <img :src="card.img" alt="Icon" class="card-image" />
            <img
              class="watercolor-effect"
              :style="{
                '-webkit-mask-image': `url('/acquerello.png')`,
                'mask-image': `url('/acquerello.png', backgroundImage: 'url(acquerello.png)')`,
                'background-color': card.class
              }"
            />
          </div>

          <!-- Testo -->
          <div class="text-center font-semibold mb-4">
            {{ card.description }}
          </div>

          <!-- Pulsante Start -->
          <div v-if="wallet">
            <button
              style="background-color: #3c82f6"
              class="text-white py-2 px-4 rounded-full glowing-border"
              @click="handleImmediateAction(card)"
              :disabled="card.disabled"
            >
              <!-- Se la card ha un URL e non è disabilitata, il pulsante deve aprire l'URL -->
              <a
                v-if="card.url && !card.disabled"
                :href="card.url"
                target="_blank"
                >Start</a
              >
              <a v-else-if="card.action === 'referral'" @click="copyReferralURL"
                >Coming Soon</a
              >
              <!-- Se non c'è un URL, o la card è disabilitata, mostra solo 'Start' -->
              <span v-else>Start</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Sezione delle tabelle -->
    <div class="container mx-auto px-4 py-8 z-10">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <!-- Tabella 1 -->
        <div
          class="bg-cardsBg bg-white text-gray-800 rounded-lg shadow-md p-6 overflow-hidden p-5"
        >
          <div class="max-w-xxl mx-auto">
            <h2 class="text-xl font-bold mb-4 text-left">POINTS RULES</h2>
            <p class="mb-4 text-left">
              The more you interact with Root Finance, the more points you will
              earn. The more points you have, the more rewards you will receive.
            </p>
            <h3 class="text-lg font-bold mb-4 text-left">
              START EARNING POINTS
            </h3>
            <p class="mb-4 text-left">
              Hey Rooter! To start earning points, first complete the quests and
              lend or borrow on Root Finance. Remember this rule Rooter, for
              every $100 worth of assets deposited or borrowed, you will
              accumulate Root Points every 24 hours based on your bonus.
            </p>
            <div class="mb-4">
              <div class="flex items-center">
                <h3 class="text-lg font-bold mb-1 text-left">SUPPLY BONUS</h3>
                <img src="/icon/X1.png" alt="Icon 5" class="h-10" />
              </div>
              <div class="flex items-center justify-start space-x-2 mb-2">
                <img src="/icon/radix.png" alt="radix" class="h-6 w-6" />
                <img src="/icon/xwbtc.png" alt="wwbtc" class="h-6 w-6" />
                <img src="/icon/ether.png" alt="ether" class="h-6 w-6" />
                <img src="/icon/isu.png" alt="isu" class="h-6 w-6" />
              </div>
              <div>
                <p>
                  For every $100 supplied, you earn Root Points every 24 hours
                </p>
              </div>
            </div>
            <div class="mb-4">
              <div class="flex items-center">
                <h3 class="text-lg font-bold mb-1 text-left">SUPPLY BONUS</h3>
                <img src="/icon/X2.png" alt="Icon 5" class="h-10" />
              </div>
              <div class="flex items-center justify-start space-x-2 mb-2">
                <img src="/icon/resource.png" alt="isu" class="h-6 w-6" />
                <img src="/icon/tether.png" alt="isu" class="h-6 w-6" />
              </div>
              <div>
                <p>
                  For every $100 supplied, you earn Root Points every 24 hours
                </p>
              </div>
            </div>
            <div class="mb-4">
              <div class="flex items-center">
                <h3 class="text-lg font-bold mb-1 text-left">SUPPLY BONUS</h3>
                <img src="/icon/X3.png" alt="Icon 5" class="h-10" />
              </div>
              <div class="flex items-center justify-start space-x-2 mb-2">
                <img src="/icon/hug.png" alt="isu" class="h-6 w-6" />
              </div>
              <div>
                <p>
                  For every $100 supplied, you earn Root Points every 24 hours
                </p>
              </div>
            </div>

            <p class="text-xs mb-4 text-left">
              <em
                >Example: If you deposit $1000 worth of XRD you will earn 10
                Root Points every 24 hours</em
              >
            </p>
            <div class="mb-4">
              <div class="flex items-center">
                <h3 class="text-lg font-bold mb-1 text-left">BORROW BONUS</h3>
                <img src="/icon/X3.png" alt="Icon 5" class="h-10" />
              </div>
              <div class="flex items-center justify-start space-x-2 mb-2">
                <img src="/icon/radix.png" alt="radix" class="h-6 w-6" />
                <img src="/icon/xwbtc.png" alt="wwbtc" class="h-6 w-6" />
                <img src="/icon/ether.png" alt="ether" class="h-6 w-6" />
                <img src="/icon/isu.png" alt="isu" class="h-6 w-6" />
                <img src="/icon/resource.png" alt="isu" class="h-6 w-6" />
                <img src="/icon/tether.png" alt="isu" class="h-6 w-6" />
              </div>
              <div>
                <p>
                  For every $100 supplied, you earn Root Points every 24 hours
                </p>
              </div>
            </div>
            <h3 class="text-lg font-bold mb-2 text-left">REFERRAL BONUS</h3>
            <p class="text-sm mb-4 text-left">
              If you spread the Rootvolution to other users, you will earn an
              extra 10% of their points every 24 hours.
            </p>
            <p class="text-xs mb-4 text-left">
              <em
                >Example: If your friend earns 100 Root Points every 24 hours,
                you will earn an extra 10 Root Points every 24 hours.</em
              >
            </p>
            <h3 class="text-lg font-bold mb-2 text-left">
              IGNITION UNLOCK BONUS
            </h3>
            <p class="text-sm mb-4 text-left">
              Have you participated in the Liquidity Ignition and are now
              unlocking your liquidity? Supply your assets on Root Finance to
              earn a 2X more multiplier on top of the multiplier you are already
              earning.
            </p>
            <p class="text-xs mb-4 text-left">
              <em
                >Example: If you supply USDC, you earn a 2x multiplier every 24
                hours. But if you supply liquidity coming from the Ignition
                unlock, you will earn an extra 2X multiplier resulting in a 4X
                multiplier!</em
              >
            </p>
          </div>
        </div>
        <!-- Tabella 2 (Classifica Wallet) -->
        <div
          class="overflow-hidden rounded-lg p-6 bg-cardsBg bg-white text-gray-800 rounded-lg shadow-md"
        >
          <h2 class="text-xl font-bold mb-4 text-center">LEADERBOARD</h2>
          <div v-if="leaderboard && leaderboard.length" class="p-5">
            <table class="min-w-full">
              <thead>
                <tr>
                  <td class="px-1 mx-auto pb-6 pl-0">
                    <div class="w-full flex justify-start">
                      <span
                        class="align-middle font-semibold text-sm uppercase whitespace-nowrap"
                      >
                        wallet
                      </span>
                    </div>
                  </td>
                  <td class="px-1 mx-auto pb-6">
                    <div class="w-full flex justify-center">
                      <span
                        class="align-middle font-semibold text-sm uppercase whitespace-nowrap"
                      >
                        points
                      </span>
                    </div>
                  </td>
                  <td class="px-1 mx-auto pb-6">
                    <div class="w-full flex justify-center">
                      <span
                        class="align-middle font-semibold text-sm uppercase whitespace-nowrap"
                      >
                        pos
                      </span>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in leaderboard"
                  :key="item._id"
                  class="transition-colors h-12"
                >
                  <td class="px-1 mx-auto pl-1">
                    <div class="flex items-center gap-2">
                      <span class="align-middle font-normal text-base">{{
                        truncateWallet(item.wallet)
                      }}</span>
                    </div>
                  </td>
                  <td class="px-1 mx-auto">
                    <div class="flex justify-center w-full">
                      <span
                        class="align-middle font-normal text-base whitespace-nowrap"
                        >{{ item.totalPoints }}</span
                      >
                    </div>
                  </td>
                  <td class="px-1 mx-auto">
                    <template v-if="index === 0">
                      <div class="flex justify-center w-full">
                        <img
                          src="images/icon/first-icon.png"
                          class="wallet-image"
                        />
                      </div>
                    </template>
                    <template v-else-if="index === 1">
                      <div class="flex justify-center w-full">
                        <img
                          src="images/icon/second-icon.png"
                          class="wallet-image"
                        />
                      </div>
                    </template>
                    <template v-else-if="index === 2">
                      <div class="flex justify-center w-full">
                        <img
                          src="images/icon/third-icon.png"
                          class="wallet-image"
                        />
                      </div>
                    </template>
                    <template v-else>
                      <div class="flex justify-center w-full">
                        <span>{{ index + 1 }}</span>
                        <!-- Posizione -->
                      </div>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div
      class="flex items-center justify-center h-screen bg-gradient-to-b from-[#631c5c] to-[#183a62]"
    >
      <div class="text-center w-full h-full">
        <img
          src="/coming.png"
          alt="Coming Soon"
          class="mx-auto mb-4 w-full h-full object-contain"
        />
      </div>
    </div>
  </div>
</template>

<script>
const API_URL = process.env.VUE_APP_API_URL;
import { selectedWallet } from './walletStore';
import axios from 'axios';
import WalletConnection from './components/WalletConnection.vue';
import { useToast } from 'vue-toastification';

export default {
  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast, selectedWallet };
  },
  components: {
    WalletConnection
  },
  name: 'App',
  data() {
    return {
      rolaResult: [],
      activeWallet: null,
      isDropdownOpen: false,
      coming_soon: false,
      cards: [
        {
          id: 1,
          description: 'Join our Telegram',
          action: 'telegram',
          img: 'telegram.png',
          class: '#bdd6ff',
          text: '+10',
          disabled: false,
          url: ''
        },
        {
          id: 2,
          description: 'Follow us on X',
          action: 'twitter',
          img: 'twitter.png',
          class: '#c4c2c2',
          text: '+10',
          disabled: false,
          url: ''
        },
        {
          id: 3,
          description: 'Deposit al least 200 USD worth of Assets',
          action: 'deposit_assets',
          img: 'deposit.png',
          class: '#edc1f7',
          text: 'Daily',
          url: 'https://app.rootfinance.xyz'
        },
        {
          id: 4,
          description: 'Borrow at least 100 USD worth of assets',
          action: 'borrow_assets',
          img: 'borrow.png',
          class: '#aae3b9',
          text: 'Daily',
          url: 'https://app.rootfinance.xyz'
        },
        {
          id: 5,
          description: 'Refer a friend to gain an extra 10% of their points',
          action: 'referral',
          img: 'refer.png',
          class: '#e398a1',
          text: '%10',
          url: ''
        }
      ],
      //listItems: [],
      coins: [],
      coinId: 0,
      hasAccess: false,
      wallet: '',
      referralURL: '',
      account_rdx: '',
      points: 0,
      //actions: [],
      leaderboard: [],
      userNft: 0
    };
  },
  methods: {
    async nftUser(address) {
      try {
        const response = await axios.post(`${API_URL}/api/nft-user`, {
          wallet: address
        });

        // Gestisci la risposta dal backend
        console.log('Risultato dal backend:', response.data);
        this.userNft = response.data.totalPoints;
        console.log('userNft:', this.userNft);
      } catch (error) {
        console.error(
          'Errore nella chiamata API:',
          error.response || error.message
        );
        throw error;
      }
    },

    async fetchNonFungibleLocation() {
      try {
        const response = await axios.post(
          `${API_URL}/api/non-fungible-location`,
          {
            resourceAddress:
              'resource_rdx1ngekvyag42r0xkhy2ds08fcl7f2ncgc0g74yg6wpeeyc4vtj03sa9f',
            nonFungibleId: '#7#'
          }
        );
        let result = response.data;
        console.log(result);
      } catch (error) {
        console.error(error.response || error.message);
      }
    },

    toggleDropdown() {
      if (this.rolaResult.length > 0) {
        this.isDropdownOpen = !this.isDropdownOpen;
      } else {
        console.log('Nessun indirizzo disponibile in rolaResult');
      }
    },
    selectInitialWallet(address) {
      this.selectedWallet.address = address; // Imposta il wallet principale in App.vue
      localStorage.setItem('selectedWalletAddress', address); // Salva nel localStorage
    },
    selectWallet(wallet) {
      if (wallet) {
        selectedWallet.address = wallet.address;
        this.wallet = selectedWallet.address;
        localStorage.setItem('selectedWalletAddress', wallet.address);
        this.nftUser(wallet.address);
        // Salva la selezione
        this.updateWalletAndLeaderboard();
        this.isDropdownOpen = false;
      }
    },
    updateRolaResult(accounts) {
      //console.log('Evento ricevuto in App.vue con accounts:', accounts);
      this.rolaResult = accounts || [];
    },
    truncateAddress(address) {
      if (address.length > 10) {
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
      }
      return address;
    },
    async checkWalletStatus() {
      try {
        const response = await axios.post(`${API_URL}/api/save-wallet`, {
          wallet: this.wallet
        });

        if (response.data.success) {
          // Aggiorna lo stato con tutti i dati del wallet
          this.points = response.data.points;
          this.referralURL = response.data.referralURL;
          this.cards.forEach(card => {
            // Disabilita le card già completate
            card.disabled = response.data.actions.some(
              action => action === card.action
            );
          });

          this.disableCardByAction('referral');

          // Aggiorna leaderboard e punti assegnati
          this.fetchLeaderboard();
          this.checkIfPointsAssigned();
        }
      } catch (error) {
        console.error('Errore nel controllo del wallet:', error);
      }
    },

    startCoinFall() {
      setInterval(this.createCoin, 300);
    },
    createCoin() {
      const randomX = (Math.random() - 0.1) * 2;
      const randomZ = (Math.random() - 0.1) * 1;
      const duration = Math.random() * 5 + 5; // Durata casuale tra 5 e 10 secondi
      const left = Math.random() * (window.innerWidth - 80) + 'px'; // Assicura che la moneta appaia all'interno della finestra
      const size = 'small';

      // Direzione casuale per la rotazione: 1 per orario, -1 per antiorario
      const rotationDirection = Math.random() < 0.5 ? 1 : -1;

      const coin = {
        id: this.coinId++,
        size: size,
        style: {
          backgroundImage: `url('./images/rootmap/token.png')`, // Sostituisci con il percorso della tua immagine
          left: left,
          '--randomX': `${randomX}px`,
          '--randomZ': `${randomZ}px`,
          '--rotation-direction': rotationDirection,
          animationDuration: `${duration}s`
        }
      };

      this.coins.push(coin);

      setTimeout(() => {
        this.coins = this.coins.filter(c => c.id !== coin.id);
      }, duration * 1000);
    },
    removeDiv() {
      document.querySelector('.bg-yellowRadix').style.display = 'none';
    },
    handleWalletConnected(address) {
      this.wallet = address; // Aggiorna l'indirizzo del wallet
      this.nftUser(address);
      this.updateWalletAndLeaderboard();
    },

    handleWalletDisconnected() {
      console.log('Disconnessione gestita in App.vue');
      this.wallet = ''; // Resetta l'indirizzo del wallet
      this.points = 0; // Resetta i punti

      localStorage.clear();
      this.rolaResult = [];

      // Riabilita tutte le card senza eccezioni al logout
      this.cards.forEach(card => {
        card.disabled = false;
      });
      // Resetta la leaderboard
      this.leaderboard = [];
    },

    handleImmediateAction(card) {
      if (card.disabled) return;

      if (card.action === 'telegram') {
        this.joinTelegram();
      }
      if (card.action === 'twitter') {
        this.followOnX();
      }
    },

    joinTelegram() {
      this.saveWalletPoints({ action: 'telegram', points: 10 });
      window.open('https://t.me/ROOTfinanceradix', '_blank');
    },
    followOnX() {
      this.saveWalletPoints({ action: 'twitter', points: 10 });
      window.open('https://x.com/RootFinanceXRD', '_blank');
    },

    async saveWalletPoints({ action, points }) {
      await axios.post(`${API_URL}/api/data`, {
        wallet: this.wallet,
        points: points,
        action: action
      });

      this.updateWalletAndLeaderboard();
    },
    async checkIfPointsAssigned() {
      try {
        const tokenParam = this.tokenInUrl
          ? `&referralToken=${this.tokenInUrl}`
          : '';

        const response = await axios.get(
          `${API_URL}/api/check_points?wallet=${this.wallet}${tokenParam}`
        );

        if (response.data.success) {
          this.points = response.data.points;
          const completedActions = response.data.actions;

          completedActions.forEach(action => {
            this.disableCardByAction(action);
          });
        }
      } catch (error) {
        console.error('Errore durante il controllo dei punti:', error);
      }
    },

    disableCardByAction(action) {
      const card = this.cards.find(c => c.action === action);
      if (card) {
        card.disabled = true;
      }
    },
    enableCardByAction(action) {
      const card = this.cards.find(c => c.action === action);
      if (card) {
        card.disabled = false;
      }
    },
    async fetchLeaderboard() {
      try {
        const response = await axios.get(`${API_URL}/api/leaderboard`);
        if (response.data.success) {
          this.leaderboard = response.data.wallets;
        }
      } catch (error) {
        console.error('Errore nel recupero della leaderboard:', error);
      }
    },
    truncateWallet(wallet) {
      // Controlla se il dispositivo è mobile (larghezza viewport < 768px)
      const isMobile = window.innerWidth < 768;

      if (isMobile) {
        // Su mobile, mostra solo le ultime 13 lettere
        return `...${wallet.slice(-13)}`;
      } else {
        // Su desktop, mantieni il comportamento attuale
        if (wallet.length > 30) {
          const start = wallet.substring(0, 15);
          const end = wallet.substring(wallet.length - 15);
          return `${start}...${end}`;
        }
        return wallet;
      }
    },
    copyReferralURL() {
      //console.log(this.referralURL);
      navigator.clipboard
        .writeText(this.referralURL)
        .then(() => {
          this.toast('Codice copiato!', {
            type: 'default',
            timeout: 1500,
            position: 'top-center',
            className: 'toast-color'
          });
        })
        .catch(err => {
          console.error('Errore nella copia del codice:', err);
        });
    },
    extractReferralToken() {
      const path = window.location.pathname;
      const token = path.length > 1 ? path.substring(1) : null; // Rimuove il primo "/" e ottiene il token dall'URL

      if (token) {
        this.tokenInUrl = token;
        console.log('Referral token trovato:', this.tokenInUrl);
      } else {
        this.tokenInUrl = null; // Nessun referralToken nell'URL
        //console.log('Nessun referral token trovato.');
      }
    },
    referFriend() {
      // Chiamata al backend per assegnare i punti usando il token referral
      axios
        .post(`${API_URL}/api/refer_friend`, {
          wallet: this.wallet,
          referralToken: this.tokenInUrl
        })
        .then(response => {
          if (response.data.success) {
            this.toast(response.data.message, {
              type: 'default',
              timeout: 1500,
              position: 'top-center',
              className: 'toast-color'
            });
            this.updateWalletAndLeaderboard(); // Funzione separata per aggiornare wallet e leaderboard
          } else {
            this.toast(response.data.message, {
              type: 'default',
              timeout: 1500,
              position: 'top-center',
              className: 'toast-color'
            });
          }
        })
        .catch(error => {
          console.error("Errore nell'assegnazione dei punti:", error);
        });
    },
    updateWalletAndLeaderboard() {
      console.log('Aggiornamento wallet e leaderboard');
      this.checkWalletStatus();
      this.fetchLeaderboard();
      this.checkIfPointsAssigned();
    }
  },
  created() {
    const savedWalletAddress = localStorage.getItem('selectedWalletAddress');
    if (savedWalletAddress) {
      this.selectedWallet.address = savedWalletAddress; // Imposta il wallet salvato
    }
  },
  mounted() {
    this.startCoinFall();
    this.extractReferralToken();
    if (this.tokenInUrl) {
      this.referFriend(); // Funzione separata per refer_friend
    }
  }
};
</script>

<style scoped>
.dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  padding: 8px 0;
  width: 240px;
}

.dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-item-selected {
  background-color: #e0f2fe; /* Colore di evidenziazione */
  font-weight: bold;
  color: #3c82f6; /* Colore del testo */
  border-left: 4px solid #3c82f6; /* Barra laterale per evidenziare */
}

.dropdown-item:hover {
  background-color: #f0f4f8;
}

.wallet-label {
  font-weight: bold;
  color: #3c82f6;
}

.wallet-address {
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 2px;
}
.toast-color {
  background-color: rgb(60, 130, 246);
  color: #fff;
}
.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombreggiatura fissa */
}

.card:hover {
  transform: scale(1.1); /* Ingrandimento all'hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Ombreggiatura più intensa all'hover */
}

.card:hover .glowing-border::before {
  animation: glowing 2s linear infinite;
  opacity: 1; /* Rendi visibile il bordo luminoso */
}

.disabled-card {
  background-color: lightgray;
  pointer-events: none; /* Impedire qualsiasi interazione con il pulsante disabilitato */
}

.disabled-card button {
  background-color: gray !important;
  pointer-events: none; /* Impedire qualsiasi interazione con il pulsante disabilitato */
}

.glowing-border {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.glowing-border::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: -1;
  animation: none; /* Disabilita l'animazione di default */
  opacity: 0; /* Nascondi il bordo luminoso di default */
  transition: opacity 0.3s ease; /* Transizione per l'opacità */
}

@keyframes glowing {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.coin {
  position: absolute;
  top: -80px; /* Inizia appena fuori dalla parte superiore della finestra */
  background-size: contain;
  background-repeat: no-repeat;
  animation: fall linear infinite;
  transform-style: preserve-3d; /* Mantieni l'effetto 3D */
}

.coin.large {
  width: 80px;
  height: 80px;
}

.coin.small {
  width: 40px;
  height: 40px;
}

@keyframes fall {
  0% {
    transform: translateY(0) translateX(0) translateZ(0) rotateZ(0deg);
    opacity: 1;
  }
  50% {
    transform: translateY(50vh) translateX(var(--randomX))
      translateZ(var(--randomZ))
      rotateZ(calc(180deg * var(--rotation-direction)));
    opacity: 1;
  }
  100% {
    transform: translateY(calc(100vh + 80px)) translateX(0) translateZ(0)
      rotateZ(calc(360deg * var(--rotation-direction)));
    opacity: 0;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flex-1 {
  flex: 1;
}
#background {
  background-repeat: no-repeat;
  background-size: cover;
}

.wallet-image {
  width: 32px; /* Modifica queste dimensioni in base alle tue esigenze */
  height: 32px;
  object-fit: contain; /* Mantiene le proporzioni dell'immagine */
}

.wallet-image-placeholder {
  width: 32px; /* Stesse dimensioni dell'immagine */
  height: 32px;
  text-align: center;
}

.image-container {
  position: relative;
  display: inline-block;
}

.card-image {
  position: relative;
  z-index: 1;
}

::v-deep .watercolor-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
}

.red {
  background-color: red; /* Applica colore rosso alla maschera */
}

.green {
  background-color: green; /* Applica colore verde alla maschera */
}

.blue {
  background-color: blue; /* Applica colore blu alla maschera */
}

.yellow {
  background-color: yellow; /* Applica colore giallo alla maschera */
}
</style>
