<template>
  <div>
    <radix-connect-button
      @wallet-disconnected="handleWalletDisconnected"
    ></radix-connect-button>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import {
  RadixDappToolkit,
  RadixNetwork,
  DataRequestBuilder
} from '@radixdlt/radix-dapp-toolkit';

export default {
  setup(_, { emit }) {
    const rolaResult = ref([]); // Inizializza rolaResult come array vuoto
    const walletAddress = ref(''); // Per memorizzare l'indirizzo principale del wallet

    const handleWalletDisconnected = () => {
      console.log('Disconnessione avviata');
      console.log(
        'Valore di walletAccounts prima del clear:',
        localStorage.getItem('walletAccounts')
      );

      walletAddress.value = '';
      rolaResult.value = [];
      localStorage.clear();
      emit('wallet-disconnected');
    };

    const initializeWalletConnection = () => {
      const savedWallets = JSON.parse(
        localStorage.getItem('walletAccounts') || '[]'
      );
      if (Array.isArray(savedWallets) && savedWallets.length) {
        rolaResult.value = savedWallets;
        walletAddress.value = savedWallets[0].address; // Imposta il primo address come principale
        emit('rola-result-updated', rolaResult.value);
        emit('wallet-connected', walletAddress.value);
      }
    };

    // Salva rolaResult in localStorage ogni volta che cambia
    watch(rolaResult, newVal => {
      localStorage.setItem('walletAccounts', JSON.stringify(newVal));
    });

    onMounted(() => {
      const radixDappToolkit = RadixDappToolkit({
        dAppDefinitionAddress:
          'account_rdx12ykkpf2v0f3hdqtez9yjhyt04u5ct455aqkq5scd5hlecwf20hcvd2',
        networkId: RadixNetwork.Mainnet,
        applicationName: 'rootpoints'
      });

      radixDappToolkit.walletApi.setRequestData(
        DataRequestBuilder.persona().withProof(),
        DataRequestBuilder.accounts().atLeast(1).withProof()
      );

      initializeWalletConnection();

      const getChallenge = async () => {
        try {
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/api/create-challenge`,
            {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(
              errorData.message || `HTTP error! status: ${response.status}`
            );
          }

          const data = await response.json();
          return data.challenge;
        } catch (error) {
          console.error('Errore nel recupero della challenge:', error);
          throw error;
        }
      };

      radixDappToolkit.walletApi.provideChallengeGenerator(getChallenge);

      radixDappToolkit.walletApi.dataRequestControl(async ({ accounts }) => {
        //console.log('Dati ricevuti da RadixDappToolkit:', accounts);

        if (accounts && accounts.length > 0) {
          rolaResult.value = accounts.map(account => ({
            label: account.label,
            address: account.address
          }));
          walletAddress.value = rolaResult.value[0].address; // Imposta il primo come principale

          // Emmetti l'indirizzo del wallet principale
          emit('rola-result-updated', rolaResult.value);
          emit('wallet-connected', walletAddress.value); // Emissione per App.vue
          emit('select-initial-wallet', walletAddress.value); // Emissione per il wallet principale
        }

        radixDappToolkit.walletApi.on('disconnect', () => {
          handleWalletDisconnected(); // Chiamata alla funzione di disconnessione
        });
      });

      const radixConnectButton = document.querySelector('radix-connect-button');
      radixConnectButton.addEventListener(
        'onDisconnect',
        handleWalletDisconnected
      );
    });

    onUnmounted(() => {
      const radixConnectButton = document.querySelector('radix-connect-button');
      if (radixConnectButton) {
        radixConnectButton.removeEventListener(
          'onDisconnect',
          handleWalletDisconnected
        );
      }
    });

    return {
      rolaResult,
      walletAddress,
      RadixNetwork,
      handleWalletDisconnected
    };
  }
};
</script>

<style scoped>
.logo {
  height: 40px;
  margin: 10px;
}
</style>
